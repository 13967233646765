import { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import * as quyenTruyCapApi from "../../../../../api/quyenTruyCapApi";
import * as env from "../../../../../env";
import ButtonLoading from "../../../../components/ButtonLoading";
import MultiSelectFilter from "../../../../components/MultiSelectFilter";
// BS
interface ModalEditPermissionProps {
  show: boolean;
  comboFunction?: Array<any>;
  onHide: () => void;
  className?: string;
  infoPermissionEdit?: any;
  onUpdateSetting?: (status: boolean) => void;
  dataStatus?: Array<{ value: string; label: string }>;
  ketQuaTraVe?: (trangThai: boolean, errorMessage?: string) => void;
}
const ModalEditPermission = ({
  show,
  comboFunction,
  onHide,
  infoPermissionEdit,
  onUpdateSetting,
  ketQuaTraVe,
}: ModalEditPermissionProps) => {
  let errorsObj = { tenQuyen: "", idMenu: "" };

  const [tenQuyen, setTenQuyen] = useState<string>("");
  const [moTa, setMoTa] = useState<string>("");
  const [errors, setErrors] = useState(errorsObj);
  const [hoatDong, setHoatDong] = useState<boolean>(true);
  const [listIdMenu, setListIdMenu] = useState<Array<any>>([]);
  const [defaultIdMenu, setDefaultIdMenu] = useState<Array<any>>([]);

  const [showLoading, setshowLoading] = useState(false);

  const kiemTraDuLieu = async (e: any) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (tenQuyen.trim() === "") {
      errorObj.tenQuyen = "Tên quyền" + env.ERROR_MESSEGE_NULL;
      error = true;
    }
    if (listIdMenu.length < 1) {
      errorObj.idMenu = "Tên menu" + env.ERROR_MESSEGE_NULL;
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return null;
    } else {
      setshowLoading(true);
      editSetting();
    }
  };

  const editSetting = async () => {
    const statusEdit = await quyenTruyCapApi.capNhatQuyen(
      infoPermissionEdit?.idQuyen,
      tenQuyen,
      moTa,
      hoatDong,
      listIdMenu
    );
    if (statusEdit.success) {
      ketQuaTraVe?.(true);
    } else {
      ketQuaTraVe?.(false, statusEdit?.message);
    }
    setshowLoading(false);
  };

  const getDefaultValue = () => {
    if (infoPermissionEdit?.menu.length > 0) {
      var listval: any = infoPermissionEdit?.menu.map((item: any) => {
        return item?.idMenu;
      });
      var defaultval: any = infoPermissionEdit?.menu.map((item: any) => {
        return { value: item?.idMenu, label: item?.tenMenu };
      });
      setListIdMenu(listval);
      setDefaultIdMenu(defaultval);
    } else {
      setListIdMenu([]);
      setDefaultIdMenu([]);
    }
  };
  useEffect(() => {
    setTenQuyen(infoPermissionEdit?.tenQuyen);
    setMoTa(infoPermissionEdit?.moTa);
    setHoatDong(infoPermissionEdit?.hoatDong);
    getDefaultValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, infoPermissionEdit]);

  return (
    <Fragment>
      <Modal
        className="modal fade"
        scrollable={true}
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title fs-20">
            Chỉnh sửa thông tin quyền
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group mb-2">
              <label className="text-black font-w500">
                Tên quyền <span className="text-danger ml-1">(*)</span>:
              </label>
              <div className="contact-name">
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  name="tenQuyen"
                  value={tenQuyen}
                  onChange={(e) => setTenQuyen(e.target.value)}
                  required
                />
                <span className="validation-text"></span>
                {errors.tenQuyen && (
                  <div className="text-danger fs-12">{errors.tenQuyen}</div>
                )}
              </div>
            </div>

            <div className="form-group mb-2">
              <label className="text-black font-w500">
                Tên menu:<span className="text-danger ml-1">(*)</span>:
              </label>
              <div className="contact-name">
                <MultiSelectFilter
                  // isSearch={isSearch}
                  isMulti={true}
                  options={comboFunction}
                  closeMenuOnSelect={true}
                  defaultValue={defaultIdMenu}
                  isClearable={true}
                  HandleOption={(val: Array<any>) => {
                    if (val.length === 0) {
                      setListIdMenu([]);
                    } else {
                      var data: Array<any> = [];
                      val.map((item) => {
                        return data.push(item?.value);
                      });
                      setListIdMenu(data);
                    }
                  }}
                />
                {errors.idMenu && (
                  <div className="text-danger fs-12">{errors.idMenu}</div>
                )}
              </div>
            </div>

            <div className="form-group">
              <label className="text-black font-w500">Mô tả:</label>
              <div className="contact-name">
                <textarea
                  className="form-control"
                  rows={5}
                  value={moTa}
                  onChange={(e) => setMoTa(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="form-group mt-2">
              <div className="form-check custom-checkbox d-inline-block ps-0">
                <label
                  className="form-check-label text-black font-w500 m-0 d-flex align-items-center justify-content-start"
                  htmlFor="checkActive"
                  style={{ cursor: "pointer" }}
                >
                  <input
                    type="checkbox"
                    onChange={() => setHoatDong(!hoatDong)}
                    className="form-check-input mt-0"
                    id="checkActive"
                    checked={hoatDong}
                    required
                  />
                  <div className="ms-2">Hoạt động</div>
                </label>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="text-center justify-content-center">
          <ButtonLoading
            className="btn btn-primary w-auto"
            type="submit"
            loading={showLoading}
            onClick={kiemTraDuLieu}
          >
            Xác nhận
          </ButtonLoading>
          {/* <button className="btn btn-primary font-w600 w-auto m-0" onClick={onSubmitFormModal}>
                        Xác nhận
                    </button> */}
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ModalEditPermission;
