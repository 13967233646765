import React, { Fragment } from "react";
import { Link } from "react-router-dom";
// BS
interface SubMenuItemProps {
  className?: string;
  to?: any;
  title?: string;
  keyLiTag?: string;
}
const SubMenuItem: React.FC<SubMenuItemProps> = ({
  className,
  to,
  title,
}: any) => {
  return (
    <Fragment>
      <li>
        <Link className={className} to={to}>
          {title}
        </Link>
      </li>
    </Fragment>
  );
};

export default SubMenuItem;
