import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import React, { useEffect, useState } from "react";
import * as lichSuGiaoDichApi from "../../../api/lichSuGiaoDichApi";
import PreviousTransactions from "./Dashboard/PreviousTransactions";
import moment from "moment";
import { formatPrice } from "../../../until";
import ChartBarApex from "./Dashboard/ChartBarApex";
const TotalInvoices = loadable(() =>
  pMinDelay(import("./Dashboard/TotalInvoices"), 1000)
);
const Paidinvoices = loadable(() =>
  pMinDelay(import("./Dashboard/Paidinvoices"), 1000)
);
const Unpaidinvoices = loadable(() =>
  pMinDelay(import("./Dashboard/Unpaidinvoices"), 1000)
);
const Totalinvoicessent = loadable(() =>
  pMinDelay(import("./Dashboard/Totalinvoicessent"), 1000)
);
// const ChartBarApex = loadable(() =>
// 	pMinDelay(import("./Dashboard/ChartBarApex"), 1000)
// );

interface ChartData {
  name: string;
  data: number[];
}

const Home: React.FC = ({ history }: any) => {
  // const history = useHistory();
  const [chiSoThongKe, setChiSoThongKe] =
    useState<lichSuGiaoDichApi.DanhSachChiSo>();
  const [dataChart, setDataChart] = useState<ChartData[]>([]);
  const [dataCho, setDataCho] = useState<string[]>([]);
  const ThongKeChiSo = async (type: number) => {
    if (type === 1) {
      var Loai = "Thang";
      var thangNam = moment(new Date()).format("MM/YYYY");
      const ListLichSu = await lichSuGiaoDichApi.layDanhSachChiSo(
        Loai,
        "",
        "",
        thangNam
      );
      if (ListLichSu.success) {
        setChiSoThongKe(ListLichSu?.data);
      }
    } else if (type === 3) {
      let Loai = "Tuan";
      var currentDate = moment();

      var weekStart = currentDate
        .clone()
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      var weekEnd = currentDate.clone().endOf("isoWeek").format("YYYY-MM-DD");
      const ListLichSu = await lichSuGiaoDichApi.layDanhSachChiSo(
        Loai,
        weekStart,
        weekEnd,
        ""
      );
      if (ListLichSu.success) {
        setChiSoThongKe(ListLichSu?.data);
      }
    } else {
      let Loai = "Tuan";
      var CurrentDate = moment().format("YYYY-MM-DD");
      const ListLichSu = await lichSuGiaoDichApi.layDanhSachChiSo(
        Loai,
        CurrentDate,
        CurrentDate,
        ""
      );
      if (ListLichSu.success) {
        setChiSoThongKe(ListLichSu?.data);
      }
    }
  };

  useEffect(() => {
    setDataChart([
      {
        name: "Tổng thu",
        data: [],
        //radius: 12,
      },
      {
        name: "Tổng nợ",
        data: [],
      },
    ]);
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-4 col-sm-6">
              <div className="card overflow-hidden">
                <div className="card-header border-0">
                  <div className="d-flex">
                    <span className="mt-2">
                      <i className="far fa-file-certificate text-primary fa-4x"></i>
                    </span>
                    <div className="invoices">
                      <h4>
                        {chiSoThongKe?.hoaDonXuatThanhCong
                          ? formatPrice(
                              Number(chiSoThongKe?.hoaDonXuatThanhCong)
                            )
                          : 0}
                      </h4>
                      <span>Tổng số hóa đơn xuất thành công </span>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div id="totalInvoices">
                    <TotalInvoices />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="card overflow-hidden">
                <div className="card-header border-0">
                  <div className="d-flex">
                    <span className="mt-1">
                      <i className="far fa-file-invoice text-primary fa-4x"></i>
                    </span>
                    <div className="invoices">
                      <h4>
                        {chiSoThongKe?.hoaDonXuatThanhCongHDDT
                          ? formatPrice(
                              Number(chiSoThongKe?.hoaDonXuatThanhCongHDDT)
                            )
                          : 0}
                      </h4>
                      <span>Tổng số phiếu thanh toán có xuất hóa đơn </span>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div id="paidinvoices">
                    <Paidinvoices />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="card overflow-hidden">
                <div className="card-header border-0">
                  <div className="d-flex">
                    <span className="mt-1">
                      <i className="far fa-file-invoice-dollar text-primary fa-4x"></i>
                    </span>
                    <div className="invoices">
                      <h4>
                        {chiSoThongKe?.tongTienThu
                          ? formatPrice(Number(chiSoThongKe?.tongTienThu))
                          : 0}
                      </h4>
                      <span>Tổng tiền thu </span>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div id="unpaidinvoices">
                    <Unpaidinvoices />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="card overflow-hidden">
                <div className="card-header border-0">
                  <div className="d-flex">
                    <span className="mt-1">
                      <span className="mt-1">
                        <i className="far fa-ballot-check text-primary fa-4x"></i>
                      </span>
                    </span>
                    <div className="invoices">
                      <h4>
                        {chiSoThongKe?.veBanRa
                          ? formatPrice(Number(chiSoThongKe?.veBanRa))
                          : 0}
                      </h4>
                      <span>Tổng số vé bán ra </span>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div id="totalinvoicessent">
                    <Totalinvoicessent />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="card overflow-hidden">
                <div className="card-header border-0">
                  <div className="d-flex">
                    <span className="mt-1">
                      <span className="mt-1">
                        <i className="far fa-file-contract text-primary fa-4x"></i>
                      </span>
                    </span>
                    <div className="invoices">
                      <h4>
                        {chiSoThongKe?.tongTienVeBanRa
                          ? formatPrice(Number(chiSoThongKe?.tongTienVeBanRa))
                          : 0}
                      </h4>
                      <span>Tổng số tiền vé </span>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div id="totalinvoicessent">
                    <Totalinvoicessent />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="card overflow-hidden">
                <div className="card-header border-0">
                  <div className="d-flex">
                    <span className="mt-1">
                      <span className="mt-1">
                        <i className="far fa-file-times text-primary fa-4x"></i>
                      </span>
                    </span>
                    <div className="invoices">
                      <h4>
                        {chiSoThongKe?.hoaDonPhatHanhLoi
                          ? formatPrice(Number(chiSoThongKe?.hoaDonPhatHanhLoi))
                          : 0}
                      </h4>
                      <span>Tổng hóa đơn lỗi </span>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div id="totalinvoicessent">
                    <Totalinvoicessent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-sm-flex d-block border-0 pb-0">
                  <div className="pe-3 me-auto mb-sm-0 mb-3">
                    <h4 className="fs-20 text-black mb-1 font-w700">
                      Thống kê
                    </h4>
                  </div>
                  {/* <div className="d-flex align-items-center justify-content-between">
										<Link to={"#"} className="btn btn-outline-primary me-3"><i className="las la-download text-primary scale5 me-3"></i>Download Report</Link>
										<Dropdown className="dropdown">
											<Dropdown.Toggle as="div" className="btn-link i-false">
												<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
											</Dropdown.Toggle>
											<Dropdown.Menu className="dropdown-menu dropdown-menu-right">
												<Dropdown.Item className="dropdown-item">Delete</Dropdown.Item>
												<Dropdown.Item className="dropdown-item">Edit</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div> */}
                </div>
                <div className="card-body">
                  <div id="chartBar" className="chartBar">
                    <ChartBarApex dataChart={dataChart} dataCho={dataCho} />
                  </div>
                  <div className="d-flex justify-content-between flex-wrap">
                    {/* <div className="d-flex">
											<label className="form-check-label font-w600 fs-16" htmlFor="flexSwitchCheckChecked1"
											>Number
											</label>
											<div className="form-check form-switch toggle-switch">
												<input className="form-check-input custome" type="checkbox"
													id="flexSwitchCheckChecked1"
													defaultChecked
												/>
											</div>
											<label className="form-check-label font-w600 fs-16" htmlFor="flexSwitchCheckChecked2">Analytics</label>
											<div className="form-check form-switch toggle-switch">
												<input className="form-check-input custome" type="checkbox"
													defaultChecked
													id="flexSwitchCheckChecked2"
												/>
											</div>
										</div> */}
                    <div>
                      <span className="fs-16 font-w600">
                        <svg
                          className="me-2"
                          width="20"
                          height="19"
                          viewBox="0 0 20 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.639771"
                            width="18.9471"
                            height="19"
                            rx="9.47357"
                            fill="#09BD3C"
                          />
                        </svg>
                        Tổng thu
                      </span>
                      <span className="fs-16 font-w600">
                        <svg
                          className="mx-2"
                          width="20"
                          height="19"
                          viewBox="0 0 20 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.344925"
                            width="18.9471"
                            height="19"
                            rx="9.47357"
                            fill="#FD5353"
                          />
                        </svg>
                        Tổng nợ
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-6 col-xxl-12 col-sm-6"></div>
              </div>
            </div>
            <div className="col-xl-12">
              <PreviousTransactions
                ThongKeChiSo={ThongKeChiSo}
                setDataChart={(data) => setDataChart(data)}
                setDataCho={(data) => setDataCho(data)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
