import { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { createPermission } from "../../../../../api/quyenTruyCapApi";
import * as env from "../../../../../env";
import ButtonLoading from "../../../../components/ButtonLoading";
import MultiSelectFilter from "../../../../components/MultiSelectFilter";
// BS
interface ModalAddPermissionProps {
  show: boolean;
  comboFunction?: Array<any>;
  onHide: () => void;
  className?: string;
  onSubmitFormModal?: () => void;
  ketQuaTraVe?: (trangThai: boolean, errorMessage?: string) => void;
}
const ModalAddPermission = ({
  show,
  comboFunction,
  onHide,
  ketQuaTraVe,
}: ModalAddPermissionProps) => {
  let errorsObj = {
    TenQuyen: "",
    idMenu: "",
    MoTa: "",
    MaQuyen: "",
    result: "",
  };
  const [TenQuyen, setTenQuyen] = useState("");
  const [MoTa, setMoTa] = useState("");
  const [errors, setErrors] = useState(errorsObj);
  const [hoatDong, setHoatDong] = useState<boolean>(true);
  const [listIdMenu, setListIdMenu] = useState<Array<any>>([]);

  const [showLoading, setshowLoading] = useState(false);

  const kiemTraDuLieu = async (e: any) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (TenQuyen === "") {
      errorObj.TenQuyen = "Tên quyền" + env.ERROR_MESSEGE_NULL;
      error = true;
    }
    if (listIdMenu.length < 1) {
      errorObj.idMenu = "Tên menu" + env.ERROR_MESSEGE_NULL;
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return null;
    } else {
      setshowLoading(true);
      addSetting();
    }
  };

  const addSetting = async () => {
    const NewPermisson = await createPermission(
      TenQuyen,
      MoTa,
      hoatDong,
      listIdMenu
    );
    if (NewPermisson.success) {
      ketQuaTraVe?.(true);
    } else {
      ketQuaTraVe?.(false, NewPermisson?.message);
    }
    setshowLoading(false);
  };

  useEffect(() => {
    if (show) {
      setTenQuyen("");
      setMoTa("");
      setHoatDong(true);
    }
  }, [show]);
  return (
    <Fragment>
      <Modal
        className="modal fade"
        scrollable={true}
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title fs-20">
            Thêm mới quyền truy cập
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {errors.result && (
              <div className="text-danger fs-12 text-center">
                {errors.result}
              </div>
            )}
            <div className="form-group mb-2">
              <label className="text-black font-w500">
                Tên quyền <span className="text-danger ml-1">(*)</span>:
              </label>
              <div className="contact-name">
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  name="priceListName"
                  required={true}
                  value={TenQuyen}
                  onChange={(e) => setTenQuyen(e.target.value)}
                />
                <span className="validation-text"></span>
                {errors.TenQuyen && (
                  <div className="text-danger fs-12">{errors.TenQuyen}</div>
                )}
              </div>
            </div>

            <div className="form-group mb-2">
              <label className="text-black font-w500">
                Tên menu:<span className="text-danger ml-1">(*)</span>:
              </label>
              <div className="contact-name">
                <MultiSelectFilter
                  // isSearch={isSearch}
                  isMulti={true}
                  options={comboFunction}
                  closeMenuOnSelect={true}
                  defaultValue={[]}
                  isClearable={true}
                  HandleOption={(val: Array<any>) => {
                    if (val.length === 0) {
                      setListIdMenu([]);
                    } else {
                      var data: Array<any> = [];
                      val.map((item) => {
                        return data.push(item?.value);
                      });
                      setListIdMenu(data);
                    }
                  }}
                />
                {errors.idMenu && (
                  <div className="text-danger fs-12">{errors.idMenu}</div>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="text-black font-w500">Mô tả:</label>
              <div className="contact-name">
                <textarea
                  className="form-control"
                  rows={5}
                  value={MoTa}
                  onChange={(e) => setMoTa(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="form-group mt-2">
              <div className="form-check custom-checkbox d-inline-block ps-0">
                <label
                  className="form-check-label text-black font-w500 m-0 d-flex align-items-center justify-content-start"
                  htmlFor="checkActive"
                  style={{ cursor: "pointer" }}
                >
                  <input
                    type="checkbox"
                    onChange={() => setHoatDong(!hoatDong)}
                    className="form-check-input mt-0"
                    id="checkActive"
                    checked={hoatDong}
                    required
                  />
                  <div className="ms-2">Hoạt động</div>
                </label>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="text-center justify-content-center">
          <ButtonLoading
            className="btn btn-primary w-auto"
            type="submit"
            loading={showLoading}
            onClick={kiemTraDuLieu}
          >
            Tiến hành thêm mới
          </ButtonLoading>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ModalAddPermission;
